@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 20px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  .m-auto {
    margin: auto;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@layer components {
  .cta-btn {
    @apply text-white bg-dark-purple border-2 border-solid border-white rounded-md font-semibold tracking-wide py-4 px-6 drop-shadow-lg hover:bg-black transition-colors duration-300 ease-in-out;
  }

  .heading {
    @apply font-caveat text-white font-black leading-none tracking-wider text-5xl sm:text-6xl;
    text-shadow: 2px 2px #000;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000;
  }

  .input {
    @apply block border-2 border-solid border-black rounded-md mb-2 p-2 drop-shadow-lg w-full;
  }
}
